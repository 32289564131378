import { Link } from "react-router-dom";
import Button from "../Button";
import { CookiesBox, CookiesContainer } from "./style";
import { useState } from "react";

export default function Cookies({}) {
  const [accepted, setAccepted] = useState(false);

  function acceptedCookie() {
    localStorage.setItem("acceptedCookies", "true");
    setAccepted(true);
  }

  return (
    <CookiesBox isClose={accepted}>
      <CookiesContainer>
        <p>
          Esse site utiliza cookies e dados pessoais de acordo com nossa{" "}
          <Link
            to={"/politica-privacidade"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de uso e privacidade de dados
          </Link>{" "}
          e, ao continuar navegando, você declara estar ciente dessas condições
          e concorda com elas.
        </p>
        <Button title="Entendi" click={() => acceptedCookie()} />
      </CookiesContainer>
    </CookiesBox>
  );
}
