import { styled } from "styled-components";
import { COLORS } from "../../theme";

type CookiesProps = {
  isClose: boolean;
};

export const CookiesBox = styled.div<CookiesProps>`
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-bottom: ${({ isClose }) => (isClose ? "-300px" : "0")};
  background-color: ${COLORS.GRAY};
  z-index: 99999999999;
  box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.3);
  transition: 0.4s all;
`;

export const CookiesContainer = styled.div`
  width: 90%;
  padding: 32px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0 18px;
  }

  a {
    color: #000;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    button {
      padding: 0;
      margin-top: 18px;
    }
  }
`;
